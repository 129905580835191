import { Fragment, useEffect, useState } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Button,
  Hidden,
  MenuItem,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { isEmpty } from 'lodash'

import {
  BasicDialog,
  MenuButton,
  MUI,
  NoData,
  PaperHeader,
  Permitted,
  Table as TableComponent,
} from 'components'
import { CompaniesLinkingForm, LinkInactivationForm } from './components'

import { useFetch } from 'hooks'

import { LinkedCompanyType } from 'types/questionnaire'
import { LinkedCompaniesType, CompanyDataType } from './linkedCompanies.types'

import service from 'service'
import constants from 'constants/index'

const LinkedCompaniesTable = ({
  disableManagement = false,
  questionnaire,
  questionnaireId,
  setIsLoading,
}: LinkedCompaniesType) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [openBindingModal, setOpenBindingModal] = useState(false)
  const [openLinkInactivationModal, setOpenLinkInactivationModal] =
    useState(false)
  const [companyData, setCompany] = useState<CompanyDataType>()

  const questionnairesLinkingFormName = 'questionnaires-linking-form'
  const questionnairesLinkInactivationForm =
    'questionnaires-link-inactivation-form'

  const { response, loading, refresh } = useFetch(
    service.dponet.questionnaires.getVinculatedCompanies,
    {
      quizTemplateId: questionnaireId,
      params: {
        page,
        perPage,
      },
    },
    [questionnaireId, questionnaire, page, perPage]
  )

  const linkedCompanies: LinkedCompanyType[] = response?.data?.companies

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
  }

  const handleOpenBindingModal = () => setOpenBindingModal(true)

  const handleCloseBindingModal = () => setOpenBindingModal(false)

  const handleOpenLinkInactivationModal = ({ id, name }: CompanyDataType) => {
    setCompany({ id, name })
    setOpenLinkInactivationModal(true)
  }

  const handleCloseLinkInactivationModal = () =>
    setOpenLinkInactivationModal(false)

  useEffect(() => {
    loading ? setIsLoading(true) : setIsLoading(false)
  }, [loading])

  return (
    <Fragment>
      <Paper variant="outlined">
        <PaperHeader
          title="Empresas Vínculadas"
          px={3}
          actionButton={
            <Permitted tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}>
              <Tooltip
                title={
                  !questionnaire?.visible &&
                  'Para vincular uma empresa a um questionário, é preciso que o questionário esteja ativo'
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PlusCircleIcon size={20} />}
                    onClick={handleOpenBindingModal}
                    disabled={!questionnaire?.visible || disableManagement}
                  >
                    Vincular empresa
                  </Button>
                </span>
              </Tooltip>
            </Permitted>
          }
        />
        {loading || isEmpty(linkedCompanies) ? (
          <NoData
            title="Nenhuma empresa vinculada a este questionário."
            subTitle={
              !disableManagement
                ? 'Por favor, associe uma empresa a este questionário utilizando o botão de vincular empresa.'
                : ''
            }
            withoutPaper
          />
        ) : (
          <Fragment>
            <TableContainer>
              <TableComponent aria-label="Listagem de empresas vínculadas">
                <TableHead>
                  <TableRow>
                    <MUI.TableCell align="left">ID</MUI.TableCell>
                    <MUI.TableCell
                      align="left"
                      width={!disableManagement ? 1400 : 1500}
                      $maxWidth={!disableManagement ? 1400 : 1500}
                    >
                      Empresa
                    </MUI.TableCell>
                    <Permitted
                      tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}
                    >
                      <MUI.TableCell align="center">Ações</MUI.TableCell>
                    </Permitted>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {linkedCompanies?.map((linkedCompany) => (
                    <TableRow key={linkedCompany?.id}>
                      <MUI.TableCell align="left">
                        {linkedCompany?.id}
                      </MUI.TableCell>
                      <MUI.TableCell
                        align="left"
                        width={!disableManagement ? 1400 : 1500}
                        $maxWidth={!disableManagement ? 1400 : 1500}
                      >
                        {linkedCompany?.name}
                      </MUI.TableCell>
                      <Permitted
                        tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}
                      >
                        <MUI.TableCell align="center">
                          <MenuButton id="teste">
                            <MenuItem
                              onClick={() =>
                                handleOpenLinkInactivationModal({
                                  id: linkedCompany?.id,
                                  name: linkedCompany?.name,
                                })
                              }
                              disabled={disableManagement}
                            >
                              Desvincular
                            </MenuItem>
                          </MenuButton>
                        </MUI.TableCell>
                      </Permitted>
                    </TableRow>
                  ))}
                </TableBody>
              </TableComponent>
            </TableContainer>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </Paper>
      <BasicDialog
        title="Aplicar para"
        form={questionnairesLinkingFormName}
        open={openBindingModal}
        onClose={handleCloseBindingModal}
        closeButtonName="Cancelar"
        saveButtonName="Vincular"
        maxWidth="md"
      >
        <CompaniesLinkingForm
          formName={questionnairesLinkingFormName}
          onClose={handleCloseBindingModal}
          questionnaireId={questionnaireId}
          refresh={refresh}
          setIsLoading={setIsLoading}
        />
      </BasicDialog>
      <BasicDialog
        title="Desvincular empresa"
        form={questionnairesLinkInactivationForm}
        open={openLinkInactivationModal}
        onClose={handleCloseLinkInactivationModal}
        saveButtonName="Desvincular"
      >
        <LinkInactivationForm
          formName={questionnairesLinkInactivationForm}
          onClose={handleCloseLinkInactivationModal}
          companyData={companyData}
          questionnaireId={questionnaireId}
          refresh={refresh}
          setIsLoading={setIsLoading}
        />
      </BasicDialog>
    </Fragment>
  )
}

export default LinkedCompaniesTable
