import { colors } from '@mui/material'

import { FormFieldType } from 'types/questionnaire'

import theme from 'theme'

const ACTIVE_QUESTION_STATUS = 'active'
const INACTIVE_QUESTION_STATUS = 'inactive'

const CONFORMITY_LEVEL_ONE = 1
const CONFORMITY_LEVEL_TWO_ = 2
const CONFORMITY_LEVEL_THREE = 3

const ACTIVE_QUESTIONNAIRE_LABEL = 'Ativo'
const INACTIVE_QUESTIONNAIRE_LABEL = 'Inativo'

const ACTIVE_QUESTION_LABEL = 'Ativo'
const INACTIVE_QUESTION_LABEL = 'Inativo'

const ALL_QUESTION_STATUS = [
  ACTIVE_QUESTION_STATUS,
  INACTIVE_QUESTION_STATUS,
] as const

const LEGAL_QUESTIONNAIRE = 'Jurídico'
const GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE = 'Governança e Cultura'
const INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE =
  'Infraestrutura e Tecnologia'
const FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE = 'Requisitos Fundamentais'

const CONFORMITY_LEVELS = [
  { value: CONFORMITY_LEVEL_ONE, label: 'Nível 1' },
  { value: CONFORMITY_LEVEL_TWO_, label: 'Nível 2' },
  { value: CONFORMITY_LEVEL_THREE, label: 'Nível 3' },
]

const QUESTIONNAIRES_COLORS = {
  [LEGAL_QUESTIONNAIRE]: theme.palette.primary.main,
  [GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE]: colors.lightBlue['A100'],
  [INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE]: colors.red['A700'],
  [FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE]: colors.green['A700'],
}

const QUESTION_CHIP_STATUS_TRANSLATION = {
  [ACTIVE_QUESTION_STATUS]: ACTIVE_QUESTION_LABEL,
  [INACTIVE_QUESTION_STATUS]: INACTIVE_QUESTION_LABEL,
}

const QUESTION_CHIP_STATUS_COLOR = {
  [ACTIVE_QUESTION_STATUS]: 'success',
  [INACTIVE_QUESTION_STATUS]: 'neutral',
}

const QUESTIONNAIRES = [
  LEGAL_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
] as const

export { QUESTIONNAIRES }

const QUESTION_FORM_FIELDS: FormFieldType[] = [
  { name: 'theme', label: 'Tema', multiline: false, rows: 1 },
  { name: 'question', label: 'Pergunta', multiline: true, rows: 4 },
  {
    name: 'explanation',
    label: 'Explicação',
    multiline: true,
    rows: 4,
  },
  {
    name: 'purpose',
    label: 'Propósito (objetivo)',
    multiline: true,
    rows: 4,
  },
  {
    name: 'securityPolicy',
    label:
      'Medida de Segurança (o conteúdo da pergunta, mas no modo afirmativo, para ser atualizado na geração de relatórios)',
    multiline: true,
    rows: 4,
  },
]

export type ChipStatusQuestionnairesType = (typeof ALL_QUESTION_STATUS)[number]

export default {
  ACTIVE_QUESTIONNAIRE_LABEL,
  INACTIVE_QUESTIONNAIRE_LABEL,
  CONFORMITY_LEVEL_ONE,
  CONFORMITY_LEVEL_TWO_,
  CONFORMITY_LEVEL_THREE,
  CONFORMITY_LEVELS,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  LEGAL_QUESTIONNAIRE,
  QUESTIONNAIRES_COLORS,
  QUESTIONNAIRES,
  ACTIVE_QUESTION_LABEL,
  INACTIVE_QUESTION_LABEL,
  ACTIVE_QUESTION_STATUS,
  INACTIVE_QUESTION_STATUS,
  QUESTION_CHIP_STATUS_TRANSLATION,
  QUESTION_CHIP_STATUS_COLOR,
  QUESTION_FORM_FIELDS,
}
