import jodit from './jodit'
import privacyPortal from './privacyPortal'
import questionnaires from './questionnaires'
import questions from './questions'
import ticketMessages from './ticketMessages'
import tickets from './tickets'

export default {
  jodit,
  privacyPortal,
  questionnaires,
  questions,
  ticketMessages,
  tickets,
}
